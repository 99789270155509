import './App.css';
import * as reducer from './reducers.js';
import React, {useContext, useState} from 'react';
import createDispatcher from './dispatcher.js';
import defaultState from './state.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

const DispatcherContext = React.createContext({dispatch: () => {}});

/*
 * Handles errors and prompt cancels
 * example usage:
  * promptDispatch(dispatch, "create_section",
  *   {
  *     order: ["name", "start", "end", "tempo"],
  *     prompts: {
  *       name: "Section name",
  *       start: "Start Measure",
  *       end: "End Measure",
  *       tempo: "Tempo"
  *     },
  *     parsers: {
  *       start: x => parseInt(x, 10),
  *       end: x => parseInt(x, 10),
  *       tempo: x => parseInt(x, 10)
  *     },
  *     validators: {
  *       name: x => x.length > 0,
  *       start: x => x >= 0,
  *       end: (x, payload) => x >= payload.start,
  *       tempo: x => x >= 0
  *     },
  *   },
  *   {path: state_path}
  * )
 */
function promptDispatch(
  dispatch, action_type, 
  {
    order: keys,
    parsers={},
    prompts,
    validators={}
  },
  payload={}
) {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    while (true) {
      const res = prompt(prompts[key]);
      if (res === null) {
        return;
      }
      if (key in parsers) {
        try {
          payload[key] = parsers[key](res);
        } catch (e) {
          continue;
        }
      } else {
        payload[key] = res;
      }
      if (key in validators) {
        if (validators[key](payload[key], payload)) {
          break;
        }
      } else {
        break;
      }
    }
  }
  dispatch({type: action_type, payload});
}

function App(props) {
  const [state, setState] = useState(defaultState);
  const dispatch = createDispatcher(state, setState);
  const [lsLoaded, setLsLoaded] = useState(false);
  if (!lsLoaded) {
    dispatch({type: "load_ls"});
    setLsLoaded(true);
  }
  const header_links = [{url: "/", name: "Sets"}]

  return (
    <DispatcherContext.Provider value={{dispatch}}>
      <Router>
        <Switch>
          <Route path="/set/:setname"><Set header_links={header_links} sets={state.sets} /></Route>
          <Route exact path="/">
            <Header items={header_links} />
            <h1>Sets</h1>
            <NavList
              create_method={() => promptDispatch(dispatch, "create_set",
                {
                  order: ["name"],
                  prompts: {
                    name: "Name"
                  },
                  validators: {
                    name: x => x.length > 0
                  },
                },
                {}
              )}
              create_string="set"
              url="/set"
              onDragEnd={createDragEnd(dispatch, [])}
              data={state}
              keys="set_order"
              reduce_key="sets"
              reduce={(parent, child) => {
                return <Percent num={reducer.set_percentage(child)} />
              }}
            />
          </Route>
          <Redirect from="/" to="/" />
        </Switch>
      </Router>
    </DispatcherContext.Provider>
  );
}

function Header(props) {
  const {dispatch} = useContext(DispatcherContext);
  const history = useHistory();
  const fileRef = React.createRef();

  return <nav className="header">
    <ul>
      {props.items.map(({url, name}, i) => (
        <li key={i}><Link to={`${url}`}>{name}</Link></li>
      ))}
    </ul>
    <ul className="header-functions">
      <button onClick={() => {
        if (window.confirm("Are you sure you want to reset the workspace?")) {
          history.replace("/");
          dispatch({type: "reset"});
        }
      }}>Reset</button>
      <button onClick={() => promptDispatch(dispatch, "save_server",
        {
          order: ["name"],
          prompts: {
            name: "File name"
          },
          validators: {
            name: x => x.length > 0
          },
        },
        {}
      )}>Save to Server</button>
      <button onClick={() => promptDispatch(dispatch, "load_server",
        {
          order: ["name"],
          prompts: {
            name: "File name"
          },
          validators: {
            name: x => x.length > 0
          },
        },
        {}
      )}>Load from Server</button>
      <button onClick={() => dispatch({type: "save_fs"})}>Save to file system</button>
      <input
        accept=".json"
        onChange={(e) => {
          if (e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = jsonString => {
              dispatch({
                type: "load_fs", payload: JSON.parse(jsonString.target.result)
              });
            }
            reader.readAsText(e.target.files[0])
          }
        }}
        ref={fileRef}
        style={{display: "none"}}
        type="file"
      />
      <button onClick={() => fileRef.current.click()}>Load from file system</button>
    </ul>
  </nav>
}

function createDragEnd(dispatch, path) {
  return function onDragEnd(length, result) {
    if (!result.destination) {
      return;
    }
    const from = result.source.index;
    let to = result.destination.index;
    if (to === length) {
      to--;
    }
    if (from !== to) {
      dispatch({type: "reorder", payload: {from, to, path}})
    }
  }
}

function NavList(props) {
  const length = props.data[props.keys].length;
  return (
    <DragDropContext onDragEnd={(result) => props.onDragEnd(length, result)}>
      <nav className="list">
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.data[props.keys].map((name, i) => (
                <Draggable key={name} draggableId={name} index={i}>
                  {(provided, snapshot) => (
                    <div className="draggable"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Link to={`${props.url}/${name}`}>
                        {name} {props.reduce(props.data, props.data[props.reduce_key][name])}
                      </Link>
                    {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              <Draggable
                draggableId="__fake__"
                isDragDisabled={true}
                index={length}
                key="__fake__"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={props.create_method}
                    className="draggable"
                  >
                  Create new {props.create_string}
                  {provided.placeholder}
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </nav>
    </DragDropContext>
  )
}

function Measure(props) {
  return <div
      className={"measure" + (props.selected ? " selected" : "")}
      onClick={props.toggle}
    >
    <p className="measure-number">{props.number}</p>
    <p className="measure-tempo">
      {props.tempo}&nbsp;
      <Percent num={props.tempo / props.goal_tempo} />
    </p>
    <p className="measure-goal">({props.goal_tempo})</p>
  </div>
}

function Percent(props) {
  return <span className="percent">({(props.num * 100).toFixed(0)}%)</span>
}

function Part(props) {
  const {partname} = useParams();
  const {url} = useRouteMatch();
  const header_links = props.header_links.slice();
  header_links.push({url: url, name: partname});

  const state_path = [...props.state_path, partname];
  const {dispatch} = useContext(DispatcherContext);
  const part = props.parts[partname];

  const [selected, setSelected] = useState(new Array(part.tempos.length).fill(false));

  const toggle = (i) => {
    setSelected(old => {
      const selected = [...old];
      selected[i] = !selected[i];
      return selected;
    });
  };

  const setAll = (to) => {
    setSelected(new Array(part.tempos.length).fill(to));
  };

  return <>
    <Header items={header_links} />
    <h1>
      {partname}&nbsp;
      <Percent num={(reducer.part_total(part) / part.tempos.length / props.tempo)} />
    </h1>
    <div className="measure-controls">
      <button onClick={() => setAll(true)}>Select all</button>
      <button onClick={() => setAll(false)}>Select none</button>
      <button onClick={() => promptDispatch(dispatch, "set_measures_tempo",
        {
          order: ["tempo"],
          prompts: {
            tempo: "Tempo"
          },
          parsers: {
            tempo: x => parseInt(x, 10)
          },
          validators: {
            tempo: x => x >= 0
          },
        },
        {path: state_path, measures: selected.map((v, i) => v ? i : -1).filter(v => v >= 0)}
      )}>
        Set tempo
      </button>
    </div>
    <div className="measure-holder">
      {part.tempos.map((tempo, i) => (
        <Measure
          goal_tempo={props.tempo}
          key={i}
          number={props.start + i}
          selected={selected[i]}
          tempo={tempo}
          toggle={() => toggle(i)}
        />
      ))}
    </div>
  </>
}

function Section(props) {
  const {secname} = useParams();
  const {path, url} = useRouteMatch();
  const header_links = props.header_links.slice();
  header_links.push({url: url, name: secname});

  const state_path = [...props.state_path, secname];
  const {dispatch} = useContext(DispatcherContext);
  const section = props.sections[secname];

  return <Switch>
    <Route exact path={path}>
      <Header items={header_links} />
      <h1>
        {secname}&nbsp;
        <span className="regular">
          (mm. {section.measure_start}&ndash;{section.measure_end}, {section.tempo} bpm)
        </span>
        &nbsp;
        <Percent num={reducer.section_percentage(section)}/>
      </h1>
      <div className="section-functions">
        <button onClick={() => promptDispatch(dispatch, "modify_section",
          {
            order: ["value"],
            prompts: {
              value: "Set tempo to"
            },
            parsers: {
              value: x => parseInt(x, 10)
            },
            validators: {
              value: x => x > 0
            },
          },
          {path: state_path, key: "tempo"}
        )}>Set tempo</button>
        <button onClick={() => promptDispatch(dispatch, "modify_section",
          {
            order: ["value"],
            prompts: {
              value: "Set start measure to"
            },
            parsers: {
              value: x => parseInt(x, 10)
            },
            validators: {
              value: x => x >= 0
            },
          },
          {path: state_path, key: "measure_start"}
        )}>Shift start measure</button>
        <button onClick={() => promptDispatch(dispatch, "modify_section",
          {
            order: ["value"],
            prompts: {
              value: "Set end measure to"
            },
            parsers: {
              value: x => parseInt(x, 10)
            }
          },
          {path: state_path, key: "measure_end"}
        )}>Adjust end measure</button>
      </div>
      <NavList
        create_method={() => promptDispatch(dispatch, "create_part",
          {
            order: ["name"],
            prompts: {
              name: "Name"
            },
            validators: {
              name: x => x.length > 0
            },
          },
          {path: state_path}
        )}
        create_string="part"
        data={section}
        keys="part_order"
        reduce_key="parts"
        reduce={(parent, child) => {
          return <Percent num={reducer.part_total(child) / child.tempos.length / parent.tempo} />
        }}
        url={url}
        onDragEnd={createDragEnd(dispatch, state_path)}
      />
    </Route>
    <Route path={`${path}/:partname`}>
      <Part
        end={section.measure_end}
        header_links={header_links}
        parts={section.parts}
        start={section.measure_start}
        state_path={state_path}
        tempo={section.tempo}
      />
    </Route>
    <Redirect from={path} to={path} />
  </Switch>
}

function Piece(props) {
  const {piecename} = useParams();
  const {path, url} = useRouteMatch();
  const header_links = props.header_links.slice();
  header_links.push({url: url, name: piecename});

  const state_path = [...props.state_path, piecename];
  const {dispatch} = useContext(DispatcherContext);

  return <Switch>
    <Route exact path={path}>
      <Header items={header_links} />
      <h1>{piecename} <Percent num={reducer.piece_percentage(props.pieces[piecename])}/></h1>
      <NavList
        create_method={() => promptDispatch(dispatch, "create_section",
          {
            order: ["name", "start", "end", "tempo"],
            prompts: {
              name: "Section name",
              start: "Start Measure",
              end: "End Measure",
              tempo: "Tempo"
            },
            parsers: {
              start: x => parseInt(x, 10),
              end: x => parseInt(x, 10),
              tempo: x => parseInt(x, 10)
            },
            validators: {
              name: x => x.length > 0,
              start: x => x >= 0,
              end: (x, payload) => x >= payload.start,
              tempo: x => x >= 0
            },
          },
          {path: state_path}
        )}
        create_string="section"
        url={url}
        onDragEnd={createDragEnd(dispatch, state_path)}
        data={props.pieces[piecename]}
        keys="section_order"
        reduce_key="sections"
        reduce={(parent, child) => {
          return <>
            <span className="section-measures">
              (mm. {child.measure_start}&ndash;{child.measure_end}, {child.tempo} bpm)
            </span>
            &nbsp;
            <Percent num={reducer.section_percentage(child)} />
          </>
        }}
      />
    </Route>
    <Route path={`${path}/:secname`}>
      <Section
        header_links={header_links}
        sections={props.pieces[piecename].sections}
        state_path={state_path}
      />
    </Route>
    <Redirect from={path} to={path} />
  </Switch>
}

function Set(props) {
  const {setname} = useParams();
  const {path, url} = useRouteMatch();
  const {dispatch} = useContext(DispatcherContext);
  const header_links = props.header_links.slice();
  header_links.push({url: url, name: setname});

  const state_path = [setname];

  return <Switch>
    <Route exact path={path}>
      <Header items={header_links} />
      <h1>{setname} <Percent num={reducer.set_percentage(props.sets[setname])}/></h1>
      <NavList
        create_method={() => promptDispatch(dispatch, "create_piece",
          {
            order: ["name"],
            prompts: {
              name: "Name"
            },
            validators: {
              name: x => x.length > 0
            },
          },
          {path: state_path}
        )}
        create_string="piece"
        url={url}
        onDragEnd={createDragEnd(dispatch, state_path)}
        data={props.sets[setname]}
        keys="piece_order"
        reduce_key="pieces"
        reduce={(parent, child) => {
          return <Percent num={reducer.piece_percentage(child)} />
        }}
      />
    </Route>
    <Route path={`${path}/:piecename`}>
      <Piece
        header_links={header_links}
        pieces={props.sets[setname].pieces}
        state_path={[state_path]}
    />
    </Route>
    <Redirect from={path} to={path} />
  </Switch>
}

export default App;

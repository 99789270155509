import defaultState from './state.js';

function create_set(state, setState, action) {
  const {name} = action.payload;
  setState({
      ...state,
      sets: {
        ...state.sets,
        [name]: {
          pieces: {},
          piece_order: []
        }
      },
      set_order: [
        ...state.set_order,
        name
      ],
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

function create_piece(state, setState, action) {
  const {name, path: [setname]} = action.payload;
  const old_set = state.sets[setname]
  setState({
      ...state,
      sets: {
        ...state.sets,
        [setname]: {
          ...old_set,
          pieces: {
            ...old_set.pieces,
            [name]: {
              sections: {},
              section_order: []
            }
          },
          piece_order: [
            ...old_set.piece_order,
            name
          ]
        }
      },
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

function create_section(state, setState, action) {
  const {end, name, path: [setname, piecename], start, tempo} = action.payload;
  const old_set = state.sets[setname]
  const old_piece = old_set.pieces[piecename];
  setState({
      ...state,
      sets: {
        ...state.sets,
        [setname]: {
          ...old_set,
          pieces: {
            ...old_set.pieces,
            [piecename]: {
              ...old_piece,
              sections: {
                ...old_piece.sections,
                [name]: {
                  measure_end: end,
                  measure_start: start,
                  part_order: [],
                  parts: {},
                  tempo: tempo
                }
              },
              section_order: [
                ...old_piece.section_order,
                name
              ]
            }
          }
        }
      },
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

function create_part(state, setState, action) {
  const {name, path: [setname, piecename, secname]} = action.payload;
  const old_set = state.sets[setname]
  const old_piece = old_set.pieces[piecename];
  const old_sec = old_piece.sections[secname];
  setState({
      ...state,
      sets: {
        ...state.sets,
        [setname]: {
          ...old_set,
          pieces: {
            ...old_set.pieces,
            [piecename]: {
              ...old_piece,
              sections: {
                ...old_piece.sections,
                [secname]: {
                  ...old_sec,
                  parts: {
                    ...old_sec.parts,
                    [name]: {
                      tempos: new Array(old_sec.measure_end - old_sec.measure_start + 1).fill(0)
                    }
                  },
                  part_order: [
                    ...old_sec.part_order,
                    name
                  ]
                }
              },
            }
          }
        }
      },
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

function set_measures_tempo(state, setState, action) {
  const {tempo, measures, path: [setname, piecename, secname, partname]} = action.payload;
  const old_set = state.sets[setname]
  const old_piece = old_set.pieces[piecename];
  const old_sec = old_piece.sections[secname];
  const old_part = old_sec.parts[partname];

  const new_tempos = [...old_part.tempos];
  measures.forEach(index => new_tempos[index] = tempo);

  setState({
      ...state,
      sets: {
        ...state.sets,
        [setname]: {
          ...old_set,
          pieces: {
            ...old_set.pieces,
            [piecename]: {
              ...old_piece,
              sections: {
                ...old_piece.sections,
                [secname]: {
                  ...old_sec,
                  parts: {
                    ...old_sec.parts,
                    [partname]: {
                      ...old_part,
                      tempos: new_tempos
                    }
                  },
                }
              },
            }
          }
        }
      },
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

function save_ls(state, setState, action) {
  localStorage.setItem("practice_data", JSON.stringify(state));
}

function load_ls(setState) {
  try {
    const state = JSON.parse(localStorage.getItem("practice_data"));
    if (!state) {
      console.log("No localStorage data found");
      return;
    }
    setState(state);
  } catch (e) {
    console.log("Couldn't load localStorage data");
  };
}

function save_fs(state) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(new Blob([JSON.stringify(state)], {type: 'application/json'}))
  link.download = "workspace.json";
  link.click();
}

function load_fs(setState, action) {
  setState(action.payload);
}

function save_server(state, action) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(state)], {type: 'application/json'});

  formData.append('workspace', blob);

  fetch(`${process.env.REACT_APP_API_SERVER}/upload/${action.payload.name}`, {
    credentials: 'same-origin',
    method: 'POST',
    body: formData
  })
  .then(response => response.text())
  .then(result => {
    console.log('Success:', result);
  })
  .catch(error => {
    console.error('Error:', error);
  });
}

function load_server(setState, action) {
  fetch(`${process.env.REACT_APP_API_SERVER}/download/${action.payload.name}.json`).then(
    res => res.json()
  ).then(json => setState(json));
}

function reset(setState) {
  setState(defaultState);
}

function modify_section(state, setState, action) {
  const [setname, piecename, secname] = action.payload.path;
  const {key, value} = action.payload;
  const old_section = state.sets[setname].pieces[piecename].sections[secname];
  const new_section = {...old_section, [key]: value};
  switch (key) {
    case "measure_start":
      new_section.measure_end += value - old_section.measure_start;
      break;
    case "measure_end":
      if (value < new_section.measure_start) {
        return;
      }
      if (value > old_section.measure_end) {
        new_section.part_order.map(part_key => new_section.parts[part_key]).forEach(part => {
          part.tempos = part.tempos.concat(new Array(value - old_section.measure_end).fill(0));
        });
      } else if (value < old_section.measure_end) {
        new_section.part_order.map(part_key => new_section.parts[part_key]).forEach(part => {
          part.tempos = part.tempos.slice(0, value - new_section.measure_start + 1);
        });
      }
      break;
    default:
      break;
  }
  setState({
      ...state,
      sets: {
        [setname]: {
          ...state.sets[setname],
          pieces: {
            [piecename]: {
              ...state.sets[setname].pieces[piecename],
              sections: {
                ...state.sets[setname].pieces[piecename].sections,
                [secname]: {...new_section}
              }
            }
          }
        }
      },
      changes: [
        ...state.changes,
        {...action, timestamp: Date.now()}
      ]
  });
}

// https://codesandbox.io/s/k260nyxq9v?file=/index.js
function _reorder(arr, from, to) {
  const res = [...arr];
  const [removed] = res.splice(from, 1);
  res.splice(to, 0, removed);
  return res;
}

function reorder_parts(state, setState, action) {
  const {path, from, to} = action.payload;
  const [setname, piecename, secname] = path;
  const old_set = state.sets[setname];
  const old_piece = old_set.pieces[piecename];
  const old_sec = old_piece.sections[secname];
  setState({
    ...state,
    sets: {
      ...state.sets,
      [setname]: {
        ...old_set,
        pieces: {
          ...old_set.pieces,
          [piecename]: {
            ...old_piece,
            sections: {
              ...old_piece.sections,
              [secname]: {
                ...old_sec,
                part_order: _reorder(old_sec.part_order, from, to)
              }
            }
          },
        }
      }
    },
    changes: [
      ...state.changes,
      {...action, timestamp: Date.now()}
    ]
  });
}

function reorder_sections(state, setState, action) {
  const {path, from, to} = action.payload;
  const [setname, piecename] = path;
  const old_set = state.sets[setname];
  const old_piece = old_set.pieces[piecename];
  setState({
    ...state,
    sets: {
      ...state.sets,
      [setname]: {
        ...old_set,
        pieces: {
          ...old_set.pieces,
          [piecename]: {
            ...old_piece,
            section_order: _reorder(old_piece.section_order, from, to)
          },
        }
      }
    },
    changes: [
      ...state.changes,
      {...action, timestamp: Date.now()}
    ]
  });
}

function reorder_pieces(state, setState, action) {
  const {path, from, to} = action.payload;
  const [setname] = path;
  setState({
    ...state,
    sets: {
      ...state.sets,
      [setname]: {
        ...state.sets[setname],
        piece_order: _reorder(state.sets[setname].piece_order, from, to)
      }
    },
    changes: [
      ...state.changes,
      {...action, timestamp: Date.now()}
    ]
  });
}

function reorder_sets(state, setState, action) {
  const {from, to} = action.payload;
  setState({
    ...state,
    set_order: _reorder(state.set_order, from, to),
    changes: [
      ...state.changes,
      {...action, timestamp: Date.now()}
    ]
  });
}

function reorder(state, setState, action) {
  switch (action.payload.path.length) {
    case 0:
      reorder_sets(state, setState, action)
      break;
    case 1:
      reorder_pieces(state, setState, action);
      break;
    case 2:
      reorder_sections(state, setState, action);
      break;
    case 3:
      reorder_parts(state, setState, action);
      break;
    default:
      console.log("Path length unrecognized");
      break;
  }
}

export default function(state, setReactState) {
  const setState = function(state) {
    localStorage.setItem("practice_data", JSON.stringify(state));
    setReactState(state);
  }
  return function(action) {
    switch (action.type) {
      case "create_set":
        create_set(state, setState, action);
        break;
      case "create_piece":
        create_piece(state, setState, action);
        break;
      case "create_section":
        create_section(state, setState, action);
        break;
      case "create_part":
        create_part(state, setState, action);
        break;
      case "set_measures_tempo":
        set_measures_tempo(state, setState, action);
        break;
      case "save_ls":
        save_ls(state);
        break;
      case "load_ls":
        load_ls(setState);
        break;
      case "save_fs":
        save_fs(state);
        break;
      case "load_fs":
        load_fs(setState, action);
        break;
      case "save_server":
        save_server(state, action);
        break;
      case "load_server":
        load_server(setState, action);
        break;
      case "reset":
        reset(setState);
        break;
      case "modify_section":
        modify_section(state, setState, action);
        break;
      case "reorder":
        reorder(state, setState, action);
        break;
      default:
        break;
    }
  }
}

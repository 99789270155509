export default {
  sets: {},
  set_order: [],
  changes: [],
  original: {
    sets: {},
    set_order: [],
    changes: []
  }
}

function sum(a, b) {
  return a + b;
}

function part_total(part) {
  return part.tempos.reduce(sum, 0);
}

function section_total(section) {
  return section.part_order.map(key => section.parts[key]).map(part_total).reduce(sum, 0);
}

function section_goal(section) {
  return section.part_order.length * section.tempo * (section.measure_end - section.measure_start + 1);
}

function section_percentage(section) {
  return section_total(section) / section_goal(section);
}

function piece_total(piece) {
  return piece.section_order.map(key => piece.sections[key]).map(section_total).reduce(sum, 0);
}

function piece_goal(piece) {
  return piece.section_order.map(key => piece.sections[key]).map(section_goal).reduce(sum, 0);
}

function piece_percentage(piece) {
  return piece_total(piece) / piece_goal(piece);
}

function set_total(set) {
  return set.piece_order.map(key => set.pieces[key]).map(piece_total).reduce(sum, 0);
}

function set_goal(set) {
  return set.piece_order.map(key => set.pieces[key]).map(piece_goal).reduce(sum, 0);
}

function set_percentage(set) {
  return set_total(set) / set_goal(set);
}

export {
  part_total,
  piece_goal,
  piece_percentage,
  piece_total,
  section_goal,
  section_percentage,
  section_total,
  set_goal,
  set_percentage,
  set_total,
  sum,
};
